function formatNumberWithToFixed(nStr) {
    var decPlcs=2;
    nStr = parseFloat(nStr).toFixed(decPlcs);
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1))
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    return x1 + x2;
  }

  function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
  }
  
  function roundToFour(num) {
    return +(Math.round(num + "e+4") + "e-4");
  }
  
  function inArrayForString(needle, haystack) {
    var length = haystack.length;
    for (var i = 0; i < length; i++) {
      if (haystack[i].toLowerCase() == needle.toLowerCase())
        return true;
    }
    return false;
  }

function convertToLowerCase(text) {
    if (typeof text !== 'string') {
        return text;
    }
    return text.toLowerCase();
}
function convertToUpperCase(text) {
    if (typeof text !== 'string') {
        return text;
    }
    return text.toUpperCase();
}
function capitalizeFirstLetter(str) {
    return str[0].toUpperCase() + str.slice(1);
  }

  

  module.exports.formatNumberWithToFixed=formatNumberWithToFixed;
  module.exports.roundToTwo=roundToTwo;
  module.exports.roundToFour=roundToFour;
  module.exports.inArrayForString=inArrayForString;
  module.exports.convertToLowerCase=convertToLowerCase;
  module.exports.convertToUpperCase=convertToUpperCase;
  module.exports.capitalizeFirstLetter=capitalizeFirstLetter;