import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import MaterialTable from "material-table";
import Sidebar from '../../Template/Sidebar/Sidebar';
import Header from '../../Template/Header/Header';
import { baseUrl } from '../../../config';
import DatePicker from "react-datepicker";
import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";
import { clickColor, inArray } from './../../../library/util';
import CloseIcon from '@mui/icons-material/Close';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './index.css';
import Loader from 'react-loader-spinner';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Toggle from '../../toggle/toggle';
import { capitalizeFirstLetter } from '../../../helper/helperFn';
import { roundToFour } from '../ProfitabilityDiagnosis/common/commanFn';
const colourStyles = {
  menuList: styles => ({
    ...styles,
    background: 'white'
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    background: isFocused
      ? 'lightblue'
      : isSelected
        ? '#2a7bc0'
        : undefined,
    zIndex: 1
  }),
  menu: base => ({
    ...base,
    zIndex: 100
  })
}
const style_advanced = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));


const AddIconClasses = { color: '#337ab7', fontSize: '30px', marginLeft: '128px', marginTop: "-15px", cursor: 'pointer' };

const styleCloseIcon = {
  margin: '-27px'
}

const animatedComponents = makeAnimated();



function DeclineReasonReportLayered() {
  const history = useHistory();
  const [selectedRow, setSelectedRow] = useState(null);
  const [type, setType] = useState('Combined')
  const [toggle, setToggle] = useState(true);
  const [reportName, setReportName] = useState(`Declines Report (${type}) by Total Volume`);
  const handleToggleChange = () => {
    setToggle(!toggle);
    toggle ? setReportName(`Declines Report ((${type}) by Customer`) : setReportName(`Declines Report ((${type}) by Total Volume`)
  };



  let titleReportName = `Declines Report (${type}) `;

  if (!toggle) {
    titleReportName += 'by Customer'
  } else {
    titleReportName += 'by Total Volume'
  }

  //console.log('titleReportName',titleReportName)

  // const [startDate, setStartDate] = useState(new Date(Date.now() - 6 * 24 * 60 * 60 * 1000))

  const [startDate, setStartDate] = useState(() => {
    const now = new Date();
    // Calculate the first day of the current month at 00:00:01
    return new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 1);
  });
  
  const [endDate, setEndDate] = useState(new Date(Date.now() - 864e5).setHours(23, 59, 0, 0));
  const [selectedrowList, setSelectedRowList] = useState([]);
  const handleSignOut = () => {
    window.localStorage.setItem('isLogin', false);
    history.push('/');
  }

  const setRowBackground = (selectedRow) => {
    if (inArray(selectedRow.tableData.id, selectedrowList) == false) {
      setSelectedRowList(oldArray => [...oldArray, selectedRow.tableData.id]);
      setSelectedRow(selectedRow.tableData.id)
      console.log(selectedrowList)
    } else {
      setSelectedRow(500000)
      setSelectedRowList([])
    }
  }


  if (localStorage.getItem('isLogin') === "false" || localStorage.getItem('isLogin') == null) {
    history.push('/');
  }


  /************* Advanced Filter ***********/
  const [group, setgroup] = useState('');
  const [groupT, setgroupT] = useState('');
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const selectInputRef = useRef();
  const [filterValueT, setFilterValueT] = useState('')
  const [filterArrayT, setFilterArrayT] = useState([]);




  const deleteAdvancedFilterTFn = (item, index) => {
    const arr = filterArrayT.filter((item, j) => j != index);
    console.log(arr)
    setFilterArrayT(arr);
  }
  const handleChangeT = (target) => {
    if (target != undefined) {
      setgroupT(target.value);
    }
  }


  checkUserTypeFn(history);

  function checkUserTypeFn(history) {
    const userdata = JSON.parse(localStorage.getItem('userdata'));
    const usertype = userdata.type;
    console.log('in check usertype fn');
    console.log(usertype)
    if (usertype != 'admin' && usertype != 'superadmin' && usertype != 'subadmin' && usertype != 'user' && usertype != 'owneradmin') {
      history.push('/');
    }
  }

  const [reportData, setReportData] = useState([])
  const [loader, setLoader] = useState(false)

  const [message, setMessage] = useState('')
  const data = [['attribute', 'attribute2'], ['value1', 'value2']];
  const [successMsg, setSuccessMsg] = useState('')
  useEffect(() => {

    return () => {
      console.log('un subscribe')
      //ResetFilter();
    }



  }, [type])

  const ResetFilter = () => {
    setMessage('')
    setEndDate(new Date(Date.now()))
    setStartDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    setReportData([]);
  }

  const fetchApi = () => {
    let filterStringT = '';
    let toggleVal = '';
    filterArrayT.forEach((i, j) => {
      filterStringT += `T.${i.field}=${i.value}*`
    })
    if (startDate == null) {
      setMessage('Date must not be blank')
      return false;
    } else {
      setMessage('')
    }
    setLoader(true)


    if (toggle == true) {
      toggleVal = 'volume';
    } else if (toggle == false) {
      toggleVal = 'customer';
    } else {
      toggleVal = 'volume';
    }
    axios.get(baseUrl + `/reports/declineReportLayered?startdate=` + moment(startDate).format('YYYY-MM-DD HH:mm:ss') + "&enddate=" + moment(endDate).format('YYYY-MM-DD HH:mm:ss') + "&filterStringT=" + filterStringT + "&toggle=" + toggleVal+"&type="+type, {}).then(response => {
      if (response.data.result.length > 0) {
        response.data.result.forEach((i, j) => {
          if (i.approval_percent != undefined) {
            i.approval_percent = roundToTwo(i.approval_percent * 100) + "%";
            i.processor=capitalizeFirstLetter(i.processor)
          }
        });
        setReportData(response.data.result);

        console.log(response.data.result)
        setLoader(false)
      } else {
        setLoader(false)
        setReportData([]);
      }

    }).catch(error => {console.log('error',error)
      setLoader(false)
      setMessage('Something went wrong');
    });
  }

  const fetchApiDownload = () => {
    let filterStringT = '';
    let toggleVal = '';
    if (toggle == true) {
      toggleVal = 'volume';
    } else if (toggle == false) {
      toggleVal = 'customer';
    } else {
      toggleVal = 'volume';
    }
    filterArrayT.forEach((i, j) => {
      filterStringT += `T.${i.field}=${i.value}*`
    })

    window.location.href = baseUrl + `/reports/declineReportLayered/download?startdate=` + moment(startDate).format('YYYY-MM-DD HH:mm:ss') + "&enddate=" + moment(endDate).format('YYYY-MM-DD HH:mm:ss') + "&filterStringT=" + filterStringT + "&toggle=" + toggleVal+"&type="+type;

  }

  const handleChangeP = (target) => {
    if (target != undefined) {
      setType(target.value);
    }
  }
  let reportTypeCategory = [
    {
      "value": "Combined",
      "label": "Combined"
    },
    {
      "value": "Cardknox",
      "label": "Cardknox"
    },
    {
      "value": "Bluesnap",
      "label": "Bluesnap"
    },
    {
      "value": "Banquest",
      "label": "Banquest"
    },
    {
      "value": "Stripe",
      "label": "Stripe"
    }
  ]
  const loadFilterLabelSource = (inputValue) => {

    let tempCategory = reportTypeCategory;
    return new Promise((resolve) => {
      resolve(tempCategory.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      ))
    });
  }


  return (

    <div id="wrapper" className>
      <Sidebar />
      <Header handleSignOut={handleSignOut} />



      <div></div>
      <div></div>
      <div></div>
      <div></div>

      <div className='errorMsg'>{message}</div>
      <div className='successMsg viewScreen'>{successMsg}</div>

      <section id="content-wrapper" className='wrapper_d'>
        <label className='reportTypeD'>Report Type</label>
        <label className='startDateStyleD'>Start Date</label>
        <label className='endDateStyleD'>End Date</label>
        <div className="input-bar">

          <div className="input-bar-item width80">
            <div style={{ width: '230px', height: '10px' }}>
              <AsyncSelect
                ref={selectInputRef}
                components={animatedComponents}
                onChange={e => handleChangeP(e)}
                cacheOptions
                name="type"
                placeholder="Report Type"
                defaultOptions
                defaultValue={reportTypeCategory[0]}
                maxMenuHeight={200}
                className="widthSelectInput"
                classNamePrefix="select"
                styles={colourStyles}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                loadOptions={loadFilterLabelSource}

              />
            </div>

          </div>

          <div className="input-bar-item width100">

            <DatePicker
              dateFormat="yyyy-MM-dd HH:mm:ss"
              selected={startDate}
              className='width141x'
              onChange={(date) => setStartDate(date)}
              placeholder='Start Date'

              showTimeSelect
              timeFormat="p"
              timeIntervals={15}
            />

          </div>

          &nbsp;&nbsp;&nbsp;

          <div className="input-bar-item width100">

            <DatePicker
              dateFormat="yyyy-MM-dd HH:mm:ss"
              selected={endDate}
              className='width141x'
              onChange={(date) => setEndDate(date)}
              placeholder='End Date'

              showTimeSelect
              timeFormat="p"
              timeIntervals={15}

            />

          </div>
          &nbsp;&nbsp;&nbsp;
          <div className="input-bar-item">
        
            {(type=='Cardknox' || type=='Combined') &&
            <Toggle toggle={toggle} handleToggleChange={handleToggleChange} />
            }
          </div>

          <div className="input-bar-item">
            <button type="button" onClick={fetchApi} className="btn btn-primary">Run Report</button>

          </div>
          <div className='input-bar-item'>
            <button type="button" onClick={ResetFilter} className="btn btn-primary">Reset</button>
          </div>

      

          <div className='downloadReportBtn'>
            {

              <button type="button" onClick={fetchApiDownload} className="btn btn-primary">Download Report in Excel</button>

            }

          </div>
        </div>
        <div className="FilterSection">
          {filterArrayT.map((item, index) => (
            <>
              <span className='filterCategory' id={index}>
                <span className="filterSpan">{item.field} = {item.value}</span>
                <CloseIcon onClick={(event) => deleteAdvancedFilterTFn(item, index)} className='closeIcon' />
              </span>
              <span className="whitespaceSpan">{'            '}</span>
            </>
          ))}
        </div>

        {loader ?
          <div className='loaderDiv'>
            <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" /></div> :

          <MaterialTable
            // onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}

            onRowClick={((evt, selectedRow) =>
              setRowBackground(selectedRow)
            )}
            columns={[
              {
                title: "Processor", field: "processor"
              },
              {
                title: "Card.Type", field: "card_type",
              },
              {
                title: "Transactions", field: "transactions",
              },
              {
                title: "Approvals", field: "approvals",
              },
              {
                title: "Declines", field: "declines",
              },
              {
                title: "Approval.Percent", field: "approval_percent",
              },

            ]}
            data={
              reportData
            }
            title={titleReportName}
            parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}

            options={{
              actionsColumnIndex: -1,
              addRowPosition: "first",
              pageSize: 10,
              loadingType: 'linear',
              paging: false,
              exportButton: true,
              maxBodyHeight: '500px',
              headerStyle: { position: 'sticky', top: 0, fontSize: '17px', fontWeight: 'bold' },
              rowStyle: rowData => ({
                backgroundColor: (selectedRow === rowData.tableData.id) ? clickColor : ''
              })



            }}

          />
        }
      </section>

    </div>

  )
}

function roundToTwo(num) {
  return +(Math.round(num + "e+2") + "e-2");
}

export default DeclineReasonReportLayered
