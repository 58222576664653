
function formatNumberWithToFixed(nStr) {
  var decPlcs = 2;
  nStr = parseFloat(nStr).toFixed(decPlcs);
  nStr += '';
  var x = nStr.split('.');
  var x1 = x[0];
  var x2 = x.length > 1 ? '.' + x[1] : '';
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1))
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  return x1 + x2;
}

function inArray(needle, haystack) {
  var length = haystack.length;
  for (var i = 0; i < length; i++) {
    if (haystack[i] == needle)
      return true;
  }
  return false;
}
function validateEmail(email) {
  console.log('email value=' + email)
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};


module.exports.clickColor = "#b4d6fa";
module.exports.validateEmail = validateEmail;
module.exports.inArray = inArray;
module.exports.formatNumberWithToFixed = formatNumberWithToFixed;
