// let common=[   
//     'channel_name','processor_name','affiliate_name','country_name','state_name'
// ]

let common=[   
    'Channel Name','Processor Name','Affiliate Name','Country Name','State Name'
]

// let common=[
//     {
//         'channel_name':'Channel Name',
//         'processor_name':'Processor Name',
//         'affiliate_name':'Affiliate Name',
//         'country_name':'Country Name',
//         'state_name':'State Name'
//     }    
    
//     ]
const getFilterColumns=(table)=>{
    let array=[];
    let prefix;
    if(table=='common'){
        array=common;
    }
    let retrunArray=[];
    array.forEach((i)=>{
        let temp={};
        temp.group=prefix+"."+i;
        temp.label=i;
        temp.value=i
        retrunArray.push(temp);
    })
    return retrunArray;
        
}



module.exports.getFilterColumns=getFilterColumns;
