import React, { useState, useEffect, useMemo, useRef,useCallback } from 'react';
import axios from 'axios';
import { useHistory, useLocation } from "react-router-dom";
import MaterialTable from "material-table";


import Sidebar from '../../../Template/Sidebar/Sidebar';
import Header from '../../../Template/Header/Header';
import { baseUrl } from '../../../../config';

import DatePicker from "react-datepicker";
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close';
import "react-datepicker/dist/react-datepicker.css";
import Typography from '@mui/material/Typography';

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Loader from 'react-loader-spinner';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { clickColor, inArray } from './../../../../library/util';
// import {toProperCase,transformValueLowerUnderscore,getUniqueFieldValueCombinations} from '../common/filterFn'
import objAdvancedFilter from './../../../../library/advancedFilterLibFn';
import { MTableToolbar } from 'material-table';

import './../css/profit.css'
import './../css/tiered.css'
import './../css/Complete.css'

import { formatNumberWithToFixed, roundToFour, roundToTwo, inArrayForString } from '../common/commanFn';


import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import Slider, { SliderThumb, SliderValueLabelProps } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';


import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Modal from '@mui/material/Modal';
import { flexbox } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import RestoreIcon from '@mui/icons-material/Restore';

// import ReportTypeSelectBox from '../../../Common/ReportTypeSelect';

const animatedComponents = makeAnimated();

const colourStyles = {
  menuList: styles => ({
    ...styles,
    background: 'white'
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    background: isFocused
      ? 'lightblue'
      : isSelected
        ? '#2a7bc0'
        : undefined,
    zIndex: 1
  }),
  menu: base => ({
    ...base,
    zIndex: 100
  })
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const AddIconClasses = { color: '#337ab7', fontSize: '30px', marginLeft: '222px', marginTop: "-15px", cursor: 'pointer' };
const RestoreIconClasses = { color: '#337ab7', fontSize: '20px', marginLeft: '5px', marginBottom: "-5px", cursor: 'pointer' };
const style_advanced = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function toProperCase(str) {
  return str         
      .replace(/\b\w/g, char => char.toUpperCase()); 
      
}
function transformValueLowerUnderscore(value) {
 
  value = value.replace(/ /g, '_').toLowerCase();
  return value;
}

function getUniqueFieldValueCombinations(data) {
  const uniqueEntries = new Map();

  data.forEach(entry => {
      const field = entry.field.toLowerCase();  // Convert field to lowercase
      const value = entry.value.toLowerCase();  // Convert value to lowercase

      // Check if the field already exists in the Map (case-insensitive)
      if (!uniqueEntries.has(field)) {
          uniqueEntries.set(field, new Set());
      }

      // Add the value to the set of values for that field
      uniqueEntries.get(field).add(value);
  });

  // Convert the Map back to an array of objects
  const result = [];
  uniqueEntries.forEach((values, field) => {
      values.forEach(value => {
          result.push({ field, value });
      });
  });

  return result;
}

function Tiered(text) {


  
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  }


  const handleClose = () => setOpen(false);

  const [OpenAdvancedFilter, setOpenAdvancedFilter] = React.useState(false);
  const handleAdvancedFilterOpen = () => setOpenAdvancedFilter(true);
  const handleAdvancedFilterClose = () => setOpenAdvancedFilter(false);

  const history = useHistory();
  const [selectedRow, setSelectedRow] = useState(null);

  const [selectedrowList, setSelectedRowList] = useState([]);


  const setRowBackground = (selectedRow) => {
    if (inArray(selectedRow.tableData.id, selectedrowList) == false) {
      setSelectedRowList(oldArray => [...oldArray, selectedRow.tableData.id]);
      setSelectedRow(selectedRow.tableData.id)
      console.log(selectedrowList)
    } else {
      setSelectedRow(500000)
      setSelectedRowList([])
    }
  }

  /******** Advanced Filter Work  */
  const handleChangeT = (target) => {
    if (target != undefined && parentGroup!=group && grandParentGroup!=group) {
      setgroupT(target.value);
    }
  }
  const loadFilterLabel = (inputValue) => {
    let tempCategory = selectLabelHeader;
    return new Promise((resolve) => {
      resolve(tempCategory.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      ))
    });
  }

  const loadFilterValues = (inputValue) => {
    let tempCategory = selectFilterValues;
    return new Promise((resolve) => {
      resolve(tempCategory.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      ))
    });
  }


  const [selectLabelHeader, setSelectLabelHeader] = useState([]);
  const [groupT, setgroupT] = useState('');

  const selectInputRefInputFilter = useRef();
  const handleChangeFilterT = (target) => {
    if (target.value != undefined) {
      setFilterValueT(target.value);
    }
  }
  const [filterValueT, setFilterValueT] = useState('')
  const [filterArrayT, setFilterArrayT] = useState([]);
  const addFilterT = () => {
    console.log(group,filterArrayT)
    if (groupT != '' && filterValueT != '') {
      let tempObj = { field: groupT, value: filterValueT };
      filterArrayT.push(tempObj);
      setFilterArrayT(getUniqueFieldValueCombinations(filterArrayT));
      
      setFilterValueT('')
    }
  }

  const deleteAdvancedFilterTFn = (item, index) => {
    const arr = filterArrayT.filter((item, j) => j != index);
    setFilterArrayT(arr);
  }



  const [ReportTitle, setReportTitle] = useState('');
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date(Date.now()).setHours(23, 59, 59, 0));

  const [group, setgroup] = useState('');
  const [parentGroup, setParentGroup] = useState('');
  const [grandParentGroup, setGrandParentGroup] = useState('Offer');

  const [parentGroupFilterValue, setParentGroupFilterValue] = useState('')
  const [parentGroupArray, setParentGroupArray] = useState([])
  const [childGroup, setChildGroup] = useState('');
  const [childGroupFilterValue, setChildGroupFilterValue] = useState('')
  const [childGroupArray, setChildGroupArray] = useState([])
  const [grandChildGroup, setGrandChildGroup] = useState('');
  const [grandChildFilterValue, setGrandChildFilterValue] = useState('');
  const [grandChildGroupArray, setGrandChildGroupArray] = useState([])
  const [groupArray, setgroupArray] = useState([]);

  const [REFCB, setREFCB] = useState(true);
  const [Refund, setRefund] = useState('9');
  const [CB, setCB] = useState('1');
  const [resetPage, setResetPage] = useState(0);



  let subTitle = '';
  if (grandParentGroup != '') {
    subTitle = `Grouped by: ${grandParentGroup}`;
  }



  if (parentGroup != '') {
    if (grandParentGroup != '') {
      subTitle = `Grouped by:${grandParentGroup}`;
    }

    subTitle += `-->${parentGroup}`;
  }
  if (group != '') {
    subTitle += `-->${group}`;
  }


  let midTitle = '';
  const location = useLocation()
  let reportTypeCategory = [
    {
      "value": "Combined",
      "label": "Combined"
    },
    {
      "value": "Shopware",
      "label": "ShopWare"
    },
    {
      "value": "Wowsuite",
      "label": "WowSuite"
    }

  ]
  const loadFilterLabelSource = (inputValue) => {

    let tempCategory = reportTypeCategory;
    return new Promise((resolve) => {
      resolve(tempCategory.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      ))
    });
  }

  const [type, setType] = useState('Combined')
  const handleChangeP = (target) => {
    console.log('target', target.value)
    if (target != undefined) {
      setType(target.value);
    }
  }
  const reportOrderType = location.pathname.split("/").pop();

  let reportTitle = `ShopWare Layered Profits Report`;

  if (type == 'Wowsuite') {
    reportTitle = `WowSuite Layered Profits Report`;
  } else if (type == 'Combined') {
    reportTitle = `Combined Layered Profits Report`;
  }

  const selectInputRef = useRef();
  const REFCBCheckBox = useRef();
  const selectChildGroupRef = useRef();
  const selectGrandChildGroupRef = useRef();
  const selectGroupRef = useRef();
  const selectParentGroupRef = useRef();
  const selectGrandParentGroupRef = useRef();


  /*********** Advanced Filter **********/

  const handleSignOut = () => {
    window.localStorage.setItem('isLogin', false);
    history.push('/');
  }

  checkUserTypeFn(history);

  function checkUserTypeFn(history) {
    const userdata = JSON.parse(localStorage.getItem('userdata'));
    const usertype = userdata.type;

    if (usertype != 'owneradmin' && usertype != 'superadmin') {
      history.push('/');
    }
  }

  const [reportData, setReportData] = useState([])
  const [loader, setLoader] = useState(false)
  axios.defaults.timeout = 36000000;//10min
  const [message, setMessage] = useState('')
  const data = [['attribute', 'attribute2'], ['value1', 'value2']];
  const [successMsg, setSuccessMsg] = useState('')
  const [selectFilterValues,setSelectFilterValues]=useState([]);


  useEffect(() => {
    setSelectLabelHeader(objAdvancedFilter.getFilterColumns('common'))
  }, [resetPage])

  useEffect(() => {
    // if (selectedOption) {
    //   // You can access the updated state here
    //   console.log("Updated selected option:", selectedOption);
    //   // Example: Re-fetch or update options based on the selected value
    // }
    console.log('*********************************')
    console.log(group,parentGroup,grandParentGroup)
    console.log('*********************************')

    // if(parentGroup==group){console.log('358')
    //   resetGroup()
    // }
    // else if(grandParentGroup==parentGroup){console.log('360')
    //   resetParentGroup();
    // }
    if(group==grandParentGroup){
      console.log('365')
      resetGroup();
    }
  }, [group,parentGroup,grandParentGroup]);


  const ResetFilter = () => {
    if (REFCB == true)
      REFCBCheckBox.current.click();
    setMessage('')
    setEndDate(new Date(Date.now()))
    setStartDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    setREFCB(false);
    setRefund('');
    setCB('');
    setgroupArray([])
    setReportData([]);
    window.location.reload('/')
  }



  const fetchApi = async () => {

    let filterStringT = '';
    console.log(filterArrayT)
    if(filterArrayT.length>0){
      //setFilterArrayT()
      //setFilterArrayT(objAdvancedFilter.getUniqueFieldValueCombinations(filterArrayT))
      //let filterArrayTTemp=JSON.parse(JSON.stringify(transformFieldsWithLowerUnderscore(filterArrayT)));
      filterArrayT.forEach((i, j) => {
        filterStringT += `${transformValueLowerUnderscore(i.field)}=${i.value}*`
      })
    }
    

    console.log('filterStringT',filterStringT)


    if (startDate == null) {
      setMessage('Start Date must not be blank')
      return false;
    } if (endDate == null) {
      setMessage('End Date must not be blank')
      return false;
    } else {
      setMessage('')
    }

    if (group != '' && parentGroup != '' && grandParentGroup != '') {
      if (grandParentGroup == parentGroup || parentGroup == group || parentGroup == group) {
        console.log(parentGroup, childGroup, grandChildGroup)
        setMessage(`Can't have same two groups`)
        return false;
      }
    }

    if (grandParentGroup == '') {
      setMessage('Error: No groups selected');
      return false;
    }


    let layeredFilter = `&group=${group}&parentGroup=${parentGroup}&grandParentGroup=${grandParentGroup}`
    let API_URL = '';
    setLoader(true);
    API_URL = baseUrl + `/reports/profitabilityDiagnosis/profittiredview/` + '?startdate=' + moment(startDate).format('YYYY-MM-DD HH:mm') + "&enddate=" + moment(endDate).format('YYYY-MM-DD HH:mm:ss') + "&refund=" + Refund + "&cb=" + CB + "&refcb=" + REFCB
    API_URL = API_URL + `&reportOrderType=` + type;
    API_URL = API_URL + layeredFilter
    API_URL = API_URL + "&filterStringT=" + filterStringT;

    try {
      const response = await axios.get(API_URL, {});
      console.log(response)
      if (response.data.result.length > 0) {
        response.data.result.forEach((i, j) => {
          i.total_revenue = "$" + formatNumberWithToFixed(i.total_revenue).toLocaleString();
          i.AOV = "$" + formatNumberWithToFixed(i.AOV).toLocaleString();
          i.cpa_cost = "$" + formatNumberWithToFixed(i.cpa_cost).toLocaleString();
          i.cpa_cost_per_order = "$" + formatNumberWithToFixed(i.cpa_cost_per_order).toLocaleString();
          i.cogs = "$" + formatNumberWithToFixed(i.cogs).toLocaleString();
          i.cogs_per_order = "$" + formatNumberWithToFixed(i.cogs_per_order).toLocaleString();
          i.refund_dollars_amount = "$" + formatNumberWithToFixed(i.refund_dollars_amount).toLocaleString();
          i.chargebacks_dollars_amount = "$" + formatNumberWithToFixed(i.chargebacks_dollars_amount).toLocaleString();
          i.processing_cost = "$" + formatNumberWithToFixed(i.processing_cost).toLocaleString();
          i.processing_cost_per_order = "$" + formatNumberWithToFixed(i.processing_cost_per_order).toLocaleString();
          i.transaction_fee_cost = "$" + formatNumberWithToFixed(i.transaction_fee_cost).toLocaleString();
          i.transaction_fee_cost_per_order = "$" + formatNumberWithToFixed(i.transaction_fee_cost_per_order).toLocaleString();
          i.net_profit = "$" + formatNumberWithToFixed(i.net_profit).toLocaleString();
          i.shipping_cost = "$" + formatNumberWithToFixed(i.shipping_cost).toLocaleString();
          i.shipping_cost_per_order = "$" + formatNumberWithToFixed(i.shipping_cost_per_order).toLocaleString();
          i.handling_cost = "$" + formatNumberWithToFixed(i.handling_cost).toLocaleString();
          i.handling_cost_per_order = "$" + formatNumberWithToFixed(i.handling_cost_per_order).toLocaleString();
          i.customer_service_cost = "$" + formatNumberWithToFixed(i.customer_service_cost).toLocaleString();
          i.customer_service_cost_per_order = "$" + formatNumberWithToFixed(i.customer_service_cost_per_order).toLocaleString();
          i.net_profit_per_order = "$" + formatNumberWithToFixed(i.net_profit_per_order).toLocaleString();
          i.profit_margin = roundToTwo(i.profit_margin * 100) + "%";
          i.chargebacks_dollars_percent = roundToTwo(i.chargebacks_dollars_percent * 100) + "%";
          i.chargebacks_rate_percent = roundToTwo(i.chargebacks_rate_percent * 100) + "%";
          i.refund_dollars_percent = roundToTwo(i.refund_dollars_percent * 100) + "%";
          i.refund_rate_percent = roundToTwo(i.refund_rate_percent * 100) + "%";
        });
        let tableData=response.data.result;
      //   tableData = tableData.sort((a,b)=>
      //     console.log(a.group);
      //     a.group.localeCompare(b.group)
        
      // });

      // tableData=tableData.sort((a,b)=>{
      //   console.log(a.group)
      //   if(a.group!='Grand Total'){
      //     return a.group.localeCompare(b.group)
      //   }
        
      // });


        setReportData(tableData);
        setLoader(false)
      } else {
        setReportData([]);
        setLoader(false)
      }
    } catch (e) {
      console.log(e.message)
      setLoader(false)
      setMessage('Something went wrong');
    }
  }

  const fetchApiDownload = () => {
    let API_URL = '';
    API_URL = baseUrl + `/reports/profitabilityDiagnosis/profittiredview/download` + '?startdate=' + moment(startDate).format('YYYY-MM-DD HH:mm') + "&enddate=" + moment(endDate).format('YYYY-MM-DD HH:mm:ss') + "&reportOrderType=" + type + "&refund=" + Refund + "&cb=" + CB + "&refcb=" + REFCB
  }
  const PrettoSlider = styled(Slider)({
    color: '#337ab7',
    height: 8,
    position: 'absolute',
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 10,
      background: 'unset',
      padding: 0,
      width: 25,
      height: 25,
      zIndex: -10,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: '#337ab7',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&:before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    },
  });



  // *** Advanced filter section *****//

  let groupCategoryList = [
    {
      "value": "Offer",
      "label": "Offer"
    },
    {
      "value": "Affiliate",
      "label": "Affiliate"
    },
    {
      "value": "Processor",
      "label": "Processor"
    }
  ]


  // tempCategory = tempCategory.filter(i => i.group != parentGroup);

  let grandParentCategory = [
    {
      "value": "Offer",
      "label": "Offer"
    }
  ]
  let parentCategory = [
    {
      "value": "Affiliate",
      "label": "Affiliate"
    }
  ]
  let groupVCategory = [
    {
      "value": "Processor",
      "label": "Processor"
    }
  ]
  const loadGroupFn = (inputValue) => {

    let tempCategory = groupCategoryList;
    //console.log(grandParentGroup, parentGroup)
    // if(grandParentGroup==parentGroup){console.log('hey 593');
    //   resetParentGroup();
    // }
    tempCategory = tempCategory.filter(i => i.value != grandParentGroup && i.value != parentGroup);
    //console.log('loadGroupFn=tempCategory', tempCategory)
    return new Promise((resolve) => {
      resolve(tempCategory.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      ))
    },500);
  }
  const loadGroupFnParent = (inputValue) => {
    //console.log('loadGroupFnParent')
    let tempCategory = groupCategoryList.filter(i => i.value != grandParentGroup && i.value!=group);
    //console.log('loadGroupFnParent=tempCategory', tempCategory)
    return new Promise((resolve) => {
      resolve(tempCategory.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      ))
    },500);
  }
  const loadGroupFnGrand = (inputValue) => {
    //console.log('loadGroupFnGrand')
    let tempCategory = groupCategoryList;
    //console.log('loadGroupFnGrand=tempCategory', tempCategory)
    //let tempCategory = groupCategoryList.filter(i => i.value != parentGroup && i.value!=group);
    return new Promise((resolve) => {
      resolve(tempCategory.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      ))
    },500);
  }

  const handleChangeParentGroup = (target) => {

    //console.log('584=',group,parentGroup,grandParentGroup)
    if (target != undefined ) {
      //if(target.value!=group && target.value!=grandParentGroup){
        setParentGroup(target.value);
        //setRefreshKey((prevKey) => prevKey + 1);
        //resetGroup();
        //console.log(selectChildGroupRef)
      // }else{
      //   setParentGroup('');
      // }
      
      resetSelectionWhenDuplicate();
      
    }

  }
  const resetSelectionWhenDuplicate=()=>{//console.log('resetSelectionWhenDuplicate',group,parentGroup,grandParentGroup)
    // if(group!='' && parentGroup!='' && group==parentGroup){console.log('600')
    //   resetGroup();
    //   //resetParentGroup();
    // }else if(parentGroup!='' && grandParentGroup!='' && parentGroup==grandParentGroup){console.log('603')
    //   resetParentGroup();
    //   //resetGrandParentGroup();
    // }else if(group!='' && grandParentGroup!='' && group==grandParentGroup){console.log('606')
    //   resetGroup();
    //   //resetGrandParentGroup();
    // }
  }
  const handleChangeGrandParentGroup = (target) => {
    // console.log('612=',group,parentGroup,grandParentGroup)
    if (target != undefined) {
      // if(target.value!=group && target.value!=parentGroup){
      setGrandParentGroup(target.value);
      //setRefreshKey((prevKey) => prevKey + 1);
      // resetGroup();
      // resetParentGroup();
      //}
    }
    console.log('620=',group,parentGroup,grandParentGroup)
    resetSelectionWhenDuplicate();

  }
  const handleChangeGroup = (target) => {
    console.log('624=',group,parentGroup,grandParentGroup)
    if (target != undefined) {
      //if(target.value!=parentGroup || target.value!=grandParentGroup){
      setgroup(target.value);
      //setRefreshKey((prevKey) => prevKey + 1);
      //}

    }
    resetSelectionWhenDuplicate();
  }



  const resetGroup = () => {
    if(group!=''){
      console.log('===',selectGroupRef.current)
      //if(selectGroupRef.current!=null){
        selectGroupRef.current.clearValue();
      //}
      
      setgroup('')
    }
    
  }
  const resetParentGroup = () => {console.log('resetParentGroup');
    if(parentGroup!=''){
      //if(selectParentGroupRef.current!=null){
        selectParentGroupRef.current.clearValue();
      //}
      
      setParentGroup('')
    }
    // if(grandParentGroup!=''){
    //   setGrandParentGroup('')
    // }
    

  }
  const resetGrandParentGroup = () => {
    console.log('481')
    //if(selectGrandParentGroupRef.current!=null){
      selectGrandParentGroupRef.current.clearValue();
    //}
    
    setParentGroup('')
    setGrandParentGroup('')
    setgroup('')

  }


  /**************Adanced Filter  ***********/

const loadGroupChildFn = (inputValue) =>
  new Promise((resolve) => {
    let tempCategory='';
    if(groupT=='Channel Name'){
      tempCategory='channel';
    }else if(groupT=='Affiliate Name'){
      tempCategory='affiliate'
    }else if(groupT=='Country Name'){
      tempCategory='country'
    }else if(groupT=='State Name'){
      tempCategory='state'
    }

    if(groupT=='Processor Name'){
    let responseResult=[
      {"group":"PayPal","label":"PayPal","value":"PayPal"},
      {"group":"Cardknox","label":"Cardknox","value":"Cardknox"},
      {"group":"Blank","label":"Blank","value":"Blank"}
    ]
    //console.log('responseResult',responseResult)

    resolve(responseResult.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
 ));
   // return responseResult;

    }
    if(tempCategory==''){
      return false;
    }
    axios.get(baseUrl+'/reports/unique/groups?type='+tempCategory, {}).then(response => {
       resolve(response.data.result.filter((i) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
       ));
  
    });
});


const [refreshKey, setRefreshKey] = useState(0); // To trigger a re-fetch


  

  
  





  return (

    <div id="wrapper" className>
      <Sidebar />
      <Header handleSignOut={handleSignOut} />
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div className='errorMsg'>{message}</div>
      <div className='successMsg viewScreen'>{successMsg}</div>
      <section id="content-wrapper">



        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className='FilterDivSection popupFilterSection'>


              <div className="input-bar-item width100">

                <div className='popupFilterElement'>
                  <label>Layer 1 Group</label>

                  <AsyncSelect
                    // ref={selectInputRef}
                    ref={selectGrandParentGroupRef}
                    key={JSON.stringify(parentGroup) || JSON.stringify(group)}
                    //key={refreshKey}
                    hideSelectedOptions={false}
                    components={animatedComponents}
                    onChange={e => handleChangeGrandParentGroup(e)}
                    cacheOptions
                    defaultOptions
                    maxMenuHeight={250}
                    className="widthSelectInput"
                    classNamePrefix="select"
                    styles={colourStyles}
                    getOptionLabel={(e) => e.value}
                    getOptionValue={(e) => e.value}
                    // loadOptions={loanGroupCategoryFnParent}
                    defaultInputValue={grandParentGroup}
                    loadOptions={loadGroupFnGrand}
                  />
                </div>
                {/* {parentGroup &&

<div className='popupFilterElement'>
<label>Parent Group Filter</label>
<AsyncSelect

key={JSON.stringify(parentGroup)}
hideSelectedOptions={false}
components={animatedComponents}
onChange={e => handleChangeParentGroupFilter(e)} 
        cacheOptions
        defaultOptions
        maxMenuHeight={250} 
        className="widthSelectInput"
        classNamePrefix="select"
         styles={colourStyles}
        getOptionLabel={(e) => e.value}
        getOptionValue={(e) => e.value}
        loadOptions={loadGroupFn}   
            
      />
</div>


} */}



              </div>
              &nbsp;&nbsp;&nbsp;
              {grandParentGroup &&
                <div className="input-bar-item width100">
                  <div className='popupFilterElement'>
                    <label>Layer 2 Group</label>
                    <RestoreIcon style={RestoreIconClasses} onClick={resetParentGroup} />
                    <AsyncSelect
                      key={JSON.stringify(grandParentGroup) || JSON.stringify(group)}
                      //key={refreshKey}
                      defaultOptions
                      ref={selectParentGroupRef}
                      hideSelectedOptions={false}
                      components={animatedComponents}
                      onChange={e => handleChangeParentGroup(e)}
                      cacheOptions
                      maxMenuHeight={250}
                      className="widthSelectInput"
                      classNamePrefix="select"
                      styles={colourStyles}
                      getOptionLabel={(e) => e.value}
                      getOptionValue={(e) => e.value}
                      // loadOptions={loanGroupCategoryFnChild}
                      defaultInputValue={parentGroup}
                      loadOptions={loadGroupFnParent}
                    />
                  </div>
                  {/* {childGroup &&
<div className='popupFilterElement'>
<label>Child Group Filter</label>
<AsyncSelect

key={JSON.stringify(childGroup)}
ref={selectInputRef}
hideSelectedOptions={false}
components={animatedComponents}
onChange={e => handleChangeChildGroupFilter(e)} 
        cacheOptions
        defaultOptions
        maxMenuHeight={250} 
        className="widthSelectInput"
        classNamePrefix="select"
         styles={colourStyles}
        getOptionLabel={(e) => e.value}
        getOptionValue={(e) => e.value}
        loadOptions={loadGroupChildFn}   
            
      />
    
</div> 
}    */}
                </div>
              }
              {parentGroup &&
                <div className="input-bar-item width100">
                  <div className='popupFilterElement'>
                    <label>Layer 3 Group</label>
                    <RestoreIcon style={RestoreIconClasses} onClick={resetGroup} />
                    {/* <RestoreIcon style={RestoreIconClasses} onClick={resetChildGroup}/> */}
                    <AsyncSelect
                      defaultOptions
                      // ref={selectGrandChildGroupRef}
                      ref={selectGroupRef}
                      hideSelectedOptions={false}
                      key={JSON.stringify(grandParentGroup) || JSON.stringify(parentGroup)}
                      //key={refreshKey}
                      components={animatedComponents}
                      onChange={e => handleChangeGroup(e)}
                      cacheOptions
                      maxMenuHeight={250}
                      className="widthSelectInput"
                      classNamePrefix="select"
                      styles={colourStyles}
                      getOptionLabel={(e) => e.value}
                      getOptionValue={(e) => e.value}
                      // loadOptions={loanGroupCategoryGrandChildFn}
                      loadOptions={loadGroupFn}
                      defaultInputValue={group}
                    />

                  </div>
                  {/* {grandChildGroup && 
<div className='popupFilterElement'>
<label>Grand Child Group Filter</label>
<AsyncSelect
key={JSON.stringify(grandChildGroup)}
ref={selectInputRef}
hideSelectedOptions={false}
components={animatedComponents}
onChange={e => handleChangeGrandChildGroupFilter(e)} 
        cacheOptions
        defaultOptions
        maxMenuHeight={250} 
        className="widthSelectInput"
        classNamePrefix="select"
         styles={colourStyles}
        getOptionLabel={(e) => e.value}
        getOptionValue={(e) => e.value}
        loadOptions={loadGroupGrandChildFn}   
            
      />
     
     
</div>
} */}
                </div>
              }





              <div className="input-bar-item closeModel">
                <CloseIcon className='closeIcon' onClick={handleClose} />
              </div>
            </div>
          </Box>
        </Modal>








        <div className='FilterDivSection'>
          <label className='groupStyle'>Group</label>
          <label className='reportTypeStyle'>Report Type</label>
          <label className='startDateStyle_combined'>Start Date</label>
          <label className='endDateStyle_combined'>End Date</label>

          {!REFCB ? '' :
            <label className='RefundStyle'>Refund Percent</label>
          }
          {!REFCB ? '' :
            <label className='chargebackStyle_combined'>Chargeback Percent</label>
          }

          <div className="input-bar">
            <div className="input-bar-item">
              <button type="button" onClick={handleOpen} className="btn btn-primary groupBtn">Groups</button>
            </div>
            <div className="input-bar-item width60">
              <div style={{ width: '155px', height: '10px' }}>
                <AsyncSelect
                  ref={selectInputRef}
                  hideSelectedOptions={false}
                  components={animatedComponents}
                  onChange={e => handleChangeP(e)}
                  cacheOptions
                  name="type"
                  placeholder="Report Type"
                  defaultOptions
                  defaultValue={reportTypeCategory[0]}
                  maxMenuHeight={200}
                  className="widthSelectInputCom"
                  classNamePrefix="select"
                  styles={colourStyles}
                  getOptionLabel={(e) => e.label}
                  getOptionValue={(e) => e.value}
                  loadOptions={loadFilterLabelSource}
                  defaultInputValue={type}
                />
              </div>
            </div>

            <div className="input-bar-item width100">

              <DatePicker
                dateFormat="yyyy-MM-dd HH:mm:ss"
                showTimeSelect
                timeFormat="p"
                timeIntervals={15}
                selected={startDate}
                className='width122x'
                onChange={(date) => setStartDate(date)}
                placeholder='Start Date'
              />

            </div>

            &nbsp;&nbsp;&nbsp;

            <div className="input-bar-item width100">

              <DatePicker
                dateFormat="yyyy-MM-dd HH:mm:ss"
                showTimeSelect
                timeFormat="p"
                timeIntervals={15}
                selected={endDate}
                className='width122x'
                onChange={(date) => setEndDate(date)}
                placeholder='End Date'
              />

            </div>
            &nbsp;&nbsp;&nbsp;

            <div className="checkboxDiv">
              <FormGroup>
                <FormControlLabel control={<Checkbox
                  ref={REFCBCheckBox}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 40 } }}
                  name='Estimate Refunds/CBs'
                  value={REFCB}
                  defaultChecked={REFCB}
                  onChange={() => setREFCB(!REFCB)}
                />}
                  label="Estimate Refunds/CBs" />
              </FormGroup>
            </div>

            {!REFCB ? '' :

              <div className="input-bar-item">


                <Box width={150}></Box>

                <PrettoSlider

                  aria-label="pretto slider"
                  defaultValue={0}
                  min={0}
                  max={15}
                  value={Refund}
                  onChange={e => setRefund(e.target.value)}
                  step={1}
                  valueLabelDisplay="on"
                  marks
                />
              </div>
            }

            {!REFCB ? '' :

              <div className="input-bar-item">



                {/* <Box sx={{ m: 3 }} /> */}
                <Box width={150}></Box>
                <PrettoSlider

                  aria-label="pretto slider"
                  defaultValue={0}
                  min={0}
                  max={15}
                  value={CB}
                  onChange={e => setCB(e.target.value)}
                  step={1}
                  valueLabelDisplay="on"
                  marks
                />
              </div>
            }


            {/* {type != 'wowsuite' ? <div className="input-bar-item">
              <button type="button" onClick={handleAdvancedFilterOpen} className="btn btn-primary">Add Filter</button>
            </div> :
              ''
            } */}

            <div className="input-bar-item">
              <button type="button" onClick={fetchApi} className="btn btn-primary">Run Report</button>

            </div>

            <div className='input-bar-item'>
              <button type="button" onClick={ResetFilter} className="btn btn-primary">Reset</button>
            </div>

            <div className='downloadReportBtn'>
              {

                <button type="button" onClick={fetchApiDownload} className="btn btn-primary">Download</button>

              }
            </div>
          </div>

        </div>

        {type != 'Wowsuite' ?
        <div className="input-bar-item">
        <button type="button" onClick={handleAdvancedFilterOpen} className="btn btn-primary">Add Filter</button>&nbsp;
        </div> :
              ''
            }
        {type != 'Wowsuite' ?
        <div className="input-bar-item">
                <div className="FilterSection">
                  {filterArrayT.map((item, index) => (
                    <>
                      <span className='filterCategory' id={index}>
                        <span className="filterSpan">{toProperCase(item.field)} = {toProperCase(item.value)}</span>
                        <CloseIcon onClick={(event) => deleteAdvancedFilterTFn(item, index)} className='closeIcon closeIconFilter' />
                      </span>
                      <span className="whitespaceSpan">{'            '}</span>
                    </>
                  ))}


                </div>

              </div>
              :
              ''
            }

        <div classname="advancedFilterSection">
          <Modal
            open={OpenAdvancedFilter}
            onClose={handleAdvancedFilterClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style_advanced}>


              <label>Filter Fields</label>
              <label className='filterValueStyle'>Filter Values</label>
              <div class="input-bar">

                <div className="input-bar-item width100">

                  <AsyncSelect
                    ref={selectInputRef}
                    components={animatedComponents}
                    onChange={e => handleChangeT(e)}
                    cacheOptions
                    defaultOptions
                    maxMenuHeight={250}
                    className="widthSelectInput"
                    classNamePrefix="select"
                    styles={colourStyles}
                    getOptionLabel={(e) => e.label}
                    getOptionValue={(e) => e.value}
                    loadOptions={loadFilterLabel}
                  />
                </div>

                <>

                  &nbsp;&nbsp;&nbsp;
                  <div className="input-bar-item width100">
                    {/* <input type='text'
                      ref={selectInputRefInputFilter}
                      className='width140x filterValue'
                      placeholder="Value"
                      onChange={e => handleChangeFilterT(e)}
                    /> */}

<div className="input-bar-item width100">

<AsyncSelect
  ref={selectInputRefInputFilter}
  components={animatedComponents}
  key={JSON.stringify(groupT)}
  onChange={e => handleChangeFilterT(e)}
  cacheOptions
  defaultOptions
  maxMenuHeight={250}
  className="widthSelectInput"
  classNamePrefix="select"
  styles={colourStyles}
  getOptionLabel={(e) => e.label}
  getOptionValue={(e) => e.value}
  loadOptions={loadGroupChildFn}
/>
</div>



                    <AddIcon style={AddIconClasses} onClick={addFilterT} />
                  </div>
                </>


                <div className="input-bar-item width100">
                  <div className="input-bar-item closeModel closeIconCommon">
                    <CloseIcon className='closeIcon' onClick={handleAdvancedFilterClose} />
                  </div>
                </div>

              </div>



              <div className="input-bar-item width100">
                <div className="FilterSection">

                  {filterArrayT.map((item, index) => (
                    <>
                      <span className='filterCategory' id={index}>
                        <span className="filterSpan">{toProperCase(item.field)} = {toProperCase(item.value)}</span>
                        <CloseIcon onClick={(event) => deleteAdvancedFilterTFn(item, index)} className='closeIcon closeIconFilter' />
                      </span>
                      <span className="whitespaceSpan">{'            '}</span>
                    </>
                  ))}


                </div>

              </div>




            </Box>
          </Modal>
        </div>
        {loader ?
          <div className='loaderDiv'>
            <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" /></div> :

          <MaterialTable
            onRowClick={((evt, selectedRow) =>
              setRowBackground(selectedRow)
            )}
            columns={[
              {
                title: "Group", field: "group",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },

              {
                title: "Total Orders", field: "total_order",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "Total Revenue", field: "total_revenue",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "AOV", field: "AOV",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "Net Profit", field: "net_profit",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },

              {
                title: "Net Profit Per Order", field: "net_profit_per_order",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "Profit Margin", field: "profit_margin",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "CPA Cost", field: "cpa_cost",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "CPA Cost Per Order", field: "cpa_cost_per_order",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "COGS", field: "cogs",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "COGS Per Order", field: "cogs_per_order",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "Shipping Cost", field: "shipping_cost",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "Shipping Cost Per Order", field: "shipping_cost_per_order",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "Number of Refunds", field: "number_of_refunds",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "Refund Rate Percent", field: "refund_rate_percent",

                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "Refund Dollars Amount", field: "refund_dollars_amount",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },

              {
                title: "Refund Dollars Percents", field: "refund_dollars_percent",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "Number of Chargebacks", field: "number_of_chargebacks",

                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "Chargebacks Rate Percent", field: "chargebacks_rate_percent",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "Chargebacks Dollars Amount", field: "chargebacks_dollars_amount",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },

              {
                title: "Chargebacks Dollars Percent", field: "chargebacks_dollars_percent",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "Processing Cost", field: "processing_cost",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "Processing Cost Per Order", field: "processing_cost_per_order",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "Transaction Fee Cost", field: "transaction_fee_cost",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "Transaction Fee Cost Per Order", field: "transaction_fee_cost_per_order",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },

              {
                title: "Handling Cost", field: "handling_cost",

                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "Handling Cost Per Order", field: "handling_cost_per_order",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "Customer Service Cost", field: "customer_service_cost",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },



              {
                title: "Customer Service Cost Per Order", field: "customer_service_cost_per_order",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
            ]}
            data={
              reportData
            }
            title={reportTitle}
            //parentChildData={(row, rows) => rows.find(a => a.id === row.parentId && row.reportType!='combined')}
            parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
            // parentChildData={
            //   (row, rows) => 
            //   {
            //     console.log(row.id === row.parentId,row.id,row.parentId)
            //     return rows.find(a => a.id === row.parentId)
            //   }

            // }
            //parentChildData={props.hasParentChildData? (row, rows) => rows.find((a) => a.id === row.parentId) : null}

            options={{
              actionsColumnIndex: -1,
              addRowPosition: "first",
              pageSize: 10,
              loadingType: 'overlay',
              paging: false,
              exportButton: true,
              maxBodyHeight: '500px',

              headerStyle: { position: 'sticky', top: 0, fontSize: '17px', fontWeight: 'bold' },
              rowStyle: rowData => ({
                backgroundColor: (selectedRow === rowData.tableData.id) ? clickColor : ''
              })
            }}
            components={{
              Toolbar: props => (
                <div>
                  <MTableToolbar {...props} />
                  <Typography variant="h6" style={{ marginLeft: '20px', color: '#337ab7' }} >
                    {subTitle}
                  </Typography>
                </div>
              ),
            }}
          />
        }
      </section>

    </div>

  )
}



export default Tiered